import domReady from '@wordpress/dom-ready';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const entryContent = document.querySelector('.entry-content');
  if (entryContent) {
    if (entryContent.classList.contains('ContentWithReadMore')) {
      if (450 < entryContent.getBoundingClientRect().height) {
        entryContent.classList.add('addButton');
        const readMoreContainer = document.getElementById(
          'readMoreBtnContainer'
        );

        readMoreContainer.innerHTML =
          '<button id="readMoreBtn" class="btn btn--outline"><span><span><i aria-hidden="true" class="icon icon-plus"></i>' +
          _x('Read more', 'js entryContent button label', 'vtx') +
          '<span class="sr-only">' +
          _x(
            'of the content.',
            'js entryContent button accessibility label',
            'vtx'
          ) +
          '</span></span></span></button>';
        entryContent.after(readMoreContainer);

        readMoreContainer.addEventListener('click', function () {
          console.log(this);
          if (entryContent.classList.contains('addButton')) {
            entryContent.classList.add('removeButton');
            entryContent.classList.remove('addButton');
            readMoreContainer.innerHTML =
              '<button id="readMoreBtn" class="btn btn--outline"><span><span><i aria-hidden="true" class="icon icon-minus"></i>' +
              _x('Read less', 'js entryContent button label', 'vtx') +
              '<span class="sr-only">' +
              _x(
                'of the content.',
                'js entryContent button accessibility label',
                'vtx'
              ) +
              '</span></span></span></button>';
          } else {
            entryContent.classList.add('addButton');
            entryContent.classList.remove('removeButton');
            readMoreContainer.innerHTML =
              '<button id="readMoreBtn" class="btn btn--outline"><span><span><i aria-hidden="true" class="icon icon-plus"></i>' +
              _x('Read more', 'js entryContent button label', 'vtx') +
              '<span class="sr-only">' +
              _x(
                'of the content.',
                'js entryContent button accessibility label',
                'vtx'
              ) +
              '</span></span></span></button>';
          }
        });
      }
    }
  }
});
