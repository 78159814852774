// Get the header
const htmlContainer = document.querySelector('html');
const header = document.querySelector('.navbar');
const logoLoginPage = document.getElementById('loginLogo');
const theme = window.theme;

const sticky = '160';

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function stickyHeader() {
  if (window.pageYOffset > sticky) {
    header.classList.add('sticky');
    htmlContainer.classList.add('sticky-navbar');
    if (logoLoginPage) {
      logoLoginPage.src = theme.asset_url + '/logo.svg';
    }
  } else {
    header.classList.remove('sticky');
    htmlContainer.classList.remove('sticky-navbar');
    if (logoLoginPage) {
      logoLoginPage.src = theme.asset_url + '/logo-reverse.svg';
    }
  }
}

// When the user scrolls the page, execute stickyHeader
window.onscroll = function () {
  stickyHeader();
};
