(function ($) {
  $('.wp-block-button__link').addClass('btn');
  const htmlBtns = $('.btn');

  htmlBtns.each(function (index, element) {
    const btnTxtAttribute = element.innerHTML;
    const btnSpan = '<span><span>' + btnTxtAttribute + '</span></span>';
    element.innerHTML = btnSpan;
  });
})(jQuery);
